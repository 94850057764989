import React from 'react'
import Swiper from '../../../../../components/swiper/autowidth'
import SimpleProduct, { EmptyItem } from '../../../../listing/components/list/simple-item'
import SimplePreOrderProduct from '../../../../listing/components/list/simple-preorder-item'

export default props => {
    const { products, column, slidesPerView = 3.5, spaceBetween = 4, sensors, top, buyButton, moreUrl, outline, isTypeFlash, festival, isTypePreOrder, dark } = props
    return products && products.length > 0 ? <Swiper outline={outline} moreUrl={moreUrl} options={{
        slidesPerView: slidesPerView,
        spaceBetween
    }} items={products} render={(item, index) => {
        return isTypePreOrder ? <SimplePreOrderProduct isTypeFlash={isTypeFlash} festival={festival} lineFeed={slidesPerView >= 4} buyButton={buyButton} top={top} sensors={sensors} column={column} product={item} position={index} /> : <SimpleProduct isTypeFlash={isTypeFlash} dark={dark} festival={festival} lineFeed={slidesPerView >= 4} buyButton={buyButton} top={top} sensors={sensors} column={column} product={item} position={index} />
    }} /> : <Swiper outline={outline} options={{
        slidesPerView: slidesPerView,
        spaceBetween
    }} items={[1, 1, 1, 1, 1]} render={(item, index) => {
        return <EmptyItem />
    }} />
}